<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row fm-background-main-color-desktop">
      <div class="col-1"></div>
      <div class="col-10">
        <NavbarNotLoggedIn></NavbarNotLoggedIn>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row fm-background-main-color-desktop">
      <div class="col-md-4"></div>
      <div class="col-md-4 fm-main-label fm-label-main-color text-center">
        <template v-if="(fieldsToDisplay === onlyCodeFields)">
          Podaj kod
        </template>
        <template v-if="(fieldsToDisplay !== onlyCodeFields)">
          Zarejestruj się
        </template>
      </div>
      <div class="col-md-4"></div>
    </div>

    <div class="row fm-background-main-color-desktop vh-100">
      <div class="col-md-4"></div>
      <div class="col-md-4 fm-z-index-10 fm-background-main-color-desktop">
        <form @submit.prevent="proceed">
          <div v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)" class="mb-4">
            <input v-model="userIdentity" type="text" class="form-control" id="userIdentity" aria-describedby="emailHelp" placeholder="Email">
          </div>
          <template v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)">
            <InputPassword @password-changed="(providedPassword) => updatePasswordValue(providedPassword)"></InputPassword>
          </template>
          <div v-if="(fieldsToDisplay === onlyCodeFields) || (fieldsToDisplay === allFields)" class="form-group">
            <input v-model="promotionCode" type="text" class="form-control" id="promotionCode" placeholder="Kod promocyjny">
          </div>
          <div v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)" class="form-check text-start">
            <label class="form-check-label" for="terms-and-conditions">Akceptuję <a v-bind:href="'pdfs/Regulamin.pdf'" target="_blank">Regulamin</a></label>
            <input v-model="termsAndConditions" type="checkbox" class="form-check-input" id="terms-and-conditions">
          </div>
          <div v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)" class="form-check text-start">
            <label class="form-check-label" for="marketing-agreement">Zgoda marketingowa</label>
            <input v-model="marketingAgreement" type="checkbox" class="form-check-input" id="marketing-agreement">
          </div>
          <template v-if="errorMessage">
            <div class="row text-start">
              <div class="col-md-12 fm-error-message">{{ errorMessage }}</div>
            </div>
          </template>
          <input type="checkbox" id="anti-bot" hidden>
          <ButtonPrimary :button-text="'Zatwierdź'" :type="'submit'"></ButtonPrimary>
        </form>
      </div>
      <div class="col-md-4"></div>
    </div>

  </template>
  <template v-if="deviceType === 'mobile'">
    <div class="container vh-100 fm-background-main-color">

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="fixed-top">
            <img v-bind:src="'images/clouds.svg'" class="img-fluid fm-100-width-image fm-header-image">
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <img v-bind:src="'images/funenglish-logo.svg'" class="img-fluid fm-logo-image" v-on:click="redirectToMainPage()">
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 fm-main-label fm-label-main-color">
          <template v-if="(fieldsToDisplay === onlyCodeFields)">
            Podaj kod
          </template>
          <template v-if="(fieldsToDisplay !== onlyCodeFields)">
            Zarejestruj się
          </template>
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 fm-z-index-10">
          <form @submit.prevent="proceed">
            <div v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)" class="mb-4">
              <input v-model="userIdentity" type="text" class="form-control" id="userIdentity" aria-describedby="emailHelp" placeholder="Email">
            </div>
            <template v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)">
              <InputPassword @password-changed="(providedPassword) => updatePasswordValue(providedPassword)"></InputPassword>
            </template>
            <div v-if="(fieldsToDisplay === onlyCodeFields) || (fieldsToDisplay === allFields)" class="form-group">
              <input v-model="promotionCode" type="text" class="form-control" id="promotionCode" placeholder="Kod promocyjny">
            </div>
            <div v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)" class="form-check text-start">
              <label class="form-check-label" for="terms-and-conditions">Akceptuję <a v-bind:href="'pdfs/Regulamin.pdf'" target="_blank">Regulamin</a></label>
              <input v-model="termsAndConditions" type="checkbox" class="form-check-input" id="terms-and-conditions">
            </div>
            <div v-if="(fieldsToDisplay === onlyRegisterFields) || (fieldsToDisplay === allFields)" class="form-check text-start">
              <label class="form-check-label" for="marketing-agreement">Zgoda marketingowa</label>
              <input v-model="marketingAgreement" type="checkbox" class="form-check-input" id="marketing-agreement">
            </div>
            <input type="checkbox" id="anti-bot" hidden>
            <ButtonPrimary :button-text="'Zatwierdź'" :type="'submit'"></ButtonPrimary>
          </form>
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 fm-z-index-0">
          <div class="fixed-bottom">
            <img v-bind:src="'images/sun.svg'" class="fm-100-width-image img-fluid" style="position: fixed; bottom: 0; right: 0;">
            <img v-bind:src="'images/squirrel.svg'" style="position: fixed; bottom: 0; right: 0;">
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>

    </div>
  </template>
</template>

<script>
import cookieService from '../services/cookieService.js';
import registerService from '../services/registerService';
import SalesPackageCodeService from '../services/salesPackageCodeService';
import NavbarNotLoggedIn from '../components/NavbarNotLoggedIn';
import InputPassword from '../components/Utils/Atom/InputPassword';
import ButtonPrimary from '../components/Utils/Atom/ButtonPrimary';

export default {
  name: 'Code',
  components: { ButtonPrimary, InputPassword, NavbarNotLoggedIn },
  data() {
    return {
      userIdentity: '',
      password: '',
      termsAndConditions: false,
      marketingAgreement: false,
      url: process.env.DOMAIN,
      errorMessage: '',
      isUserLoggedIn: false,
      displayCodeField: null,
      promotionCode: null,
      fieldsToDisplay: 'allFields',
      onlyRegisterFields: 'onlyRegisterFields',
      onlyCodeFields: 'onlyCodeFields',
      allFields: 'allFields',
      antiBot: false,
      deviceType: 'desktop'
    };
  },
  mounted() {
    console.log('test');
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();

    this.redirectToValidPage();
    this.determineFieldsToDisplay();
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    async redirectToValidPage() {
      if (this.isUserLoggedIn) {
        await this.$router.push('/pricing');
      }
    },
    determineFieldsToDisplay() {
      const currentPage = this.$route.fullPath;
      this.displayCodeField = this.$route.query.isCodeUsed ?? null;

      if (currentPage === '/code' || this.displayCodeField) {
        this.displayCodeField = true;
      } else if (currentPage === '/register') {
        this.displayCodeField = false;
      }

      this.isUserLoggedIn = this.$store.state.isUserLoggedIn;

      if (this.displayCodeField && !this.isUserLoggedIn) {
        this.fieldsToDisplay = this.allFields;
      } else if (this.displayCodeField && this.isUserLoggedIn) {
        this.fieldsToDisplay = this.onlyCodeFields;
      } else if (!this.displayCodeField && !this.isUserLoggedIn) {
        this.fieldsToDisplay = this.onlyRegisterFields;
      } else if (!this.displayCodeField && this.isUserLoggedIn) {
        this.$router.push('/funbook');
      }
    },
    async proceed() {
      const usingCodeResult = await this.checkSalesPackageCode();

      if (!usingCodeResult) {
        return;
      }

      if (this.fieldsToDisplay === this.onlyCodeFields) {
        const result = await this.useSalesPackageCode();

        if (result.isSuccess) {
          await this.$router.push(result.destination);
        }
        return;
      }

      await this.register();
    },
    async register() {
      this.antiBot = document.getElementById('anti-bot').checked;

      if (this.antiBot) {
        await registerService.logAntiBot(this.userIdentity, this.password, this.termsAndConditions, this.marketingAgreement, this.antiBot);
        await this.$router.push('/');
        return;
      }

      const response = await registerService.register(this.userIdentity, this.password, this.termsAndConditions, this.marketingAgreement);

      if (response.data.isSuccess === true) {
        cookieService.setCookie('token', response.data.token);
        this.$store.commit('setIsUserLoggedIn', true);
        const result = await this.useSalesPackageCode();

        if (result.isSuccess) {
          await this.$router.push(result.destination);
        }
      } else {
        this.errorMessage = response.data.message;
      }
    },
    async checkSalesPackageCode() {
      if (this.promotionCode) {
        const response = await SalesPackageCodeService.checkCode(this.promotionCode);
        const isCodeValid = response.data.validationResult.isCodeCanBeUsed;

        if (!isCodeValid) {
          this.errorMessage = response.data.validationResult.message;

          return false;
        }

        return true;
      }

      return true;
    },
    async useSalesPackageCode() {
      if (this.promotionCode) {
        const response = await SalesPackageCodeService.useCode(this.promotionCode);
        const salesPackageCode = response.data.salesPackageCode;

        if (salesPackageCode.isSuccess) {
          cookieService.setCookie('salesPackageCode', this.promotionCode);
          const salesPackageCodeType = salesPackageCode.code.codeType;

          if (salesPackageCodeType === 'discount') {
            cookieService.setCookie('salesPackageCodeType', salesPackageCodeType);
            cookieService.setCookie('salesPackageCodePercentageDiscount', salesPackageCode.code.percentageDiscount);

            return { isSuccess: true, destination: 'pricing' };
          }

          if (salesPackageCodeType === 'access') {
            cookieService.setCookie('salesPackageCodeType', salesPackageCodeType);
            cookieService.setCookie('salesPackageCodeNumberOfAccessDays', salesPackageCode.code.numberOfAccessDays);

            return { isSuccess: true, destination: 'funbook?access=' + salesPackageCode.code.numberOfAccessDays };
          }
        } else {
          this.errorMessage = salesPackageCode.message;

          return { isSuccess: false };
        }
      }

      return { isSuccess: true, destination: 'pricing' };
    },
    updatePasswordValue(password) {
      this.password = password;
    },
    redirectToMainPage() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.fm-z-index-0 {
  z-index: 0;
}

.fm-z-index-10 {
  z-index: 10;
}

.fm-header-image {
  padding-top: 5vh;
}

.fm-logo-image {
  padding-top: 12vh;
}

.fm-100-width-image {
  min-width: calc(100% + 24px);
  margin-left: -12px;
}

.fm-error-message {
  color: #DA3749;
  font-size: 0.75rem;
  margin-top: -0.75rem;
}
.fm-background-main-color-desktop {
  background-color: var(--blanched-almond);
}

</style>
